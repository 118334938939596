import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../components/Layout';

export default function Register() {
    const [password, setPassword] = useState('');
    const [validToken, setValidToken] = useState<boolean | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    // ✅ Extract and trim the token from URL properly
    const token = new URLSearchParams(location.search).get('token')?.trim();
    const API_URL = 'https://ridgetopbotanicals.com/api'; // ✅ Force correct API URL

    useEffect(() => {
        if (!token || token === "") {
            console.error("❌ No valid token found in URL");
            setValidToken(false);
            return;
        }

        const verifyUrl = `${API_URL}/invite/verify`;

        console.log("🔍 Verifying token at:", verifyUrl);
        console.log("🔄 Sending token:", token);

        fetch(verifyUrl, {
            method: 'POST',  // ✅ Ensure method is POST
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token }),
        })
        .then(async (response) => {
            console.log("📡 Server Response Status:", response.status);

            if (!response.ok) {
                throw new Error(`Server returned status ${response.status}`);
            }

            return response.json();
        })
        .then((data) => {
            console.log("📩 Server Response Data:", data);
            if (data.message === "Token is valid") {
                console.log("✅ Token Verified Successfully");
                setValidToken(true);
            } else {
                console.error("❌ Token Verification Failed:", data);
                setValidToken(false);
                toast.error("The invitation link is invalid or has expired.");
            }
        })
        .catch((error) => {
            console.error("🚨 Token verification request failed:", error);
            setValidToken(false);
            toast.error("Could not verify the invitation. Please try again later.");
        });
    }, [token]); // ✅ Runs only when the token changes

    const handleRegister = async () => {
        if (!password) {
            toast.error("Password is required!");
            return;
        }

        if (!token || token === "") {
            toast.error("Invalid or missing invite token.");
            return;
        }

        try {
            const response = await fetch(`${API_URL}/invite/accept`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, password }),
            });

            console.log("📡 Registration Response Status:", response.status);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Registration failed');
            }

            toast.success("Registration successful! Redirecting to login...");

            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error: any) {
            console.error("🚨 Registration error:", error);
            toast.error(error.message);
        }
    };

    if (validToken === null) {
        return <div className="min-h-screen flex items-center justify-center bg-gray-100"><p className="text-xl text-gray-700">Loading...</p></div>;
    }

    if (validToken === false) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md text-center">
                    <h1 className="text-2xl font-bold mb-4 text-red-600">Invalid Invitation</h1>
                    <p className="text-gray-600">Sorry, you need a valid invitation to set up an account.</p>
                    <ToastContainer />
                </div>
            </div>
        );
    }

    return (
        <Layout>
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-6">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Register</h1>
                <div className="mb-4">
                    <input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <button
                    onClick={handleRegister}
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out"
                >
                    Register
                </button>
                <ToastContainer />
            </div>
        </div>
        </Layout>
    );
}
